import { api, __mode__ } from "consts";

const APL_URL = "https://tysapi.tesisyonetim.pro";
const OTK_URL = "https://otokocapi.tesisyonetim.net";

export function getApiUrl() {
  switch (__mode__) {
    case api.otk:
      return OTK_URL;
    case api.apl:
      return APL_URL;
    default:
      throw new Error("Have not declare app mode");
  }
}