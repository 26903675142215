import React, { FC, useLayoutEffect, useState } from "react";
import {
  Box,
  Center,
  Text,
  Spinner,
  Button,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import DataTable from "components/atoms/DataTable";
import { TimelineLegal } from "models";
import { TimelineStatus } from "components/organisms/TimelineLegal";
import Moment from "moment";
import { FaFile } from "react-icons/fa";
import { IoFolder } from "react-icons/io5";
import InventoryFilter from "components/organisms/InventoryFilter";

interface CompletedLegalProps {}

const CompletedLegal: FC<CompletedLegalProps> = (props) => {
  const { project } = useAuth();
  const [getData, statusData, dataData] = Hooks.DocumentReports();
  const [getTime, statTime, dataTime] = Hooks.TimelineDocument();
  const [getReport, statReport, dataReport] = Hooks.ReportControlTask();
  const [filterData, setFilterData] = useState<any>(null);
  const filterDisc = useDisclosure();
  const [statusFilter, setStatusFilter] = useState([1, 2, 3]);

  useLayoutEffect(() => {
    getData(project.id);
    getTime(project.id);
    getReport(project.id);
  }, []);

  function _onFilterData(row: any) {
    function _condition(a: any, b: any) {
      return !a || a === b;
    }

    const _data = [
      _condition(filterData?.campus?.value, row.campusID),
      _condition(filterData?.build?.value, row.buildID),
      _condition(filterData?.floor?.value, row.floorID),
      _condition(filterData?.room?.value, row.roomID),
      _condition(filterData?.userType?.value, row.userTypeID),
      _condition(filterData?.group?.value, row.groupID),
      _condition(filterData?.brand?.value, row.brandID),
      _condition(filterData?.model?.value, row.modelID),
    ];

    return !_data.includes(false);
  }

  return (
    <Main headerProps={{ title: "Tamamlanan Belge Takip İşlemleri" }}>
      <Card p="3" mb="3">
        <Flex justifyContent="space-between">
          <Flex>
            <Button
              size="sm"
              mr="1"
              onClick={() =>
                setStatusFilter((prev) =>
                  prev.includes(1) ? prev.filter((i) => i !== 1) : [...prev, 1]
                )
              }
              variant={statusFilter.includes(1) ? "solid" : "outline"}
              colorScheme="purple"
            >
              Tamamlanan
            </Button>
            <Button
              size="sm"
              mr="1"
              onClick={() =>
                setStatusFilter((prev) =>
                  prev.includes(2) ? prev.filter((i) => i !== 2) : [...prev, 2]
                )
              }
              variant={statusFilter.includes(2) ? "solid" : "outline"}
              colorScheme="purple"
            >
              Planlı Zamanda Gerçekleşmeyen
            </Button>
            <Button
              size="sm"
              mr="1"
              onClick={() =>
                setStatusFilter((prev) =>
                  prev.includes(3) ? prev.filter((i) => i !== 3) : [...prev, 3]
                )
              }
              variant={statusFilter.includes(3) ? "solid" : "outline"}
              colorScheme="purple"
            >
              Form Beklenen
            </Button>
          </Flex>
          <Button
            size="sm"
            colorScheme="orange"
            mr="3"
            onClick={() => filterDisc.onToggle()}
          >
            {filterDisc.isOpen ? "Filtre Kapat" : "Filtre"}
          </Button>
        </Flex>
      </Card>
      {filterDisc.isOpen && (
        <InventoryFilter
          onFilter={(data: any) => {
            setFilterData(data);
          }}
        />
      )}

      <Card>
        {statusData !== "fulfilled" || !dataData ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <DataTable
            data={dataData.data.data
              .filter((item: any) => {
                let _data = dataTime?.data.data.find(
                  (_item: any) =>
                    item.documentMaintenanceID === _item.documentMaintenanceID
                );
                if (_data) {
                  let _statuses: any[] = [];
                  if (statusFilter.includes(1)) {
                    _statuses = [
                      ..._statuses,
                      ...[TimelineStatus.completed, TimelineStatus.lateDone],
                    ];
                  }
                  if (statusFilter.includes(2)) {
                    _statuses = [..._statuses, TimelineStatus.late];
                  }
                  if (statusFilter.includes(3)) {
                    _statuses = [..._statuses, TimelineStatus.needDocument];
                  }
                  return _statuses.includes(item.status);
                }
                return false;
              })
              .filter(_onFilterData)}
            columns={[
              {
                name: "Belge Raporu",
                cell: (row: any) => (
                  <Button
                    size="xs"
                    colorScheme="blue"
                    onClick={() => {
                      localStorage.removeItem("doc-report");
                      localStorage.setItem("doc-report", JSON.stringify(row));
                      window.open("/doc-report-document", "_blank");
                    }}
                    leftIcon={<FaFile />}
                  >
                    Raporu Görüntüle
                  </Button>
                ),
                width: "200px",
              },
              {
                name: "Kullanıcı Tipi",
                selector: "userTypeName",
                width: "200px",
              },
              { name: "Doküman Adı", selector: "documentName", width: "200px" },

              {
                name: "Periyot",
                selector: "documentMaintenancePeriodName",
                width: "150px",
              },
              {
                name: "Başlangıç  Tarihi",
                cell: (row: any) => Moment(row.startDate).format("DD.MM.YYYY"),
                width: "200px",
              },
              {
                name: "Planlı Doküman Yükleme Tarihi",
                cell: (row: any) => Moment(row.endDate).format("DD.MM.YYYY"),
                width: "200px",
              },
              {
                name: "Tamamlanma Tarihi",
                cell: (row: any) =>
                  Moment(row.completedDate).format("DD.MM.YYYY"),
                width: "200px",
              },
              {
                name: "İlk Doküman Yükleyen Kullanıcı",
                selector: "firstControlUserName",
                width: "200px",
              },
              {
                name: "İkinci Doküman Yükleyen Kullanıcı",
                selector: "secondControlUserName",
                width: "200px",
              },
              {
                name: "Notlar",
                cell: (row: any) =>
                  row.secondDate
                    ? Moment(row.secondDate).format("DD.MM.YYYY")
                    : "Mevcut Değil",
                width: "200px",
              },
              {
                name: "Soru Yanıtı",
                selector: "questionAnswer",
                width: "200px",
              },
              {
                name: "İkinci Doküman Yüklenme Notu",
                selector: "updateExplain",
                width: "200px",
              },
              { name: "Tesis", selector: "campusName", width: "200px" },
              { name: "Bina", selector: "buildName", width: "200px" },
              { name: "Kat", selector: "floorName", width: "200px" },
              { name: "Oda", selector: "roomName", width: "200px" },
              { name: "Grup", selector: "groupName", width: "200px" },
              { name: "Marka", selector: "brandName", width: "200px" },
              { name: "Model", selector: "modelName", width: "200px" },
              {
                name: "Kapasite Birimi",
                selector: "documentCapacityName",
                width: "200px",
              },
              {
                name: "Kapasite Değeri",
                selector: "documentUnit",
                width: "200px",
              },
              {
                name: "Üretim Yılı",
                selector: "documentProductionYear",
                width: "200px",
              },
            ]}
          />
        )}
      </Card>
    </Main>
  );
};

CompletedLegal.defaultProps = {};

export default CompletedLegal;
