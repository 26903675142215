import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Spinner,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import NoteCalendar from "components/organisms/NoteCalendar";
import { useGetUserOnStore } from "hooks/find-users-on-store";
import { useAuth } from "hooks/redux-hooks";
import moment from "moment";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { Hooks } from "services";
import DailyNoteItem from "./DailyNoteItem";

type NoteListProps = {};

const NoteList: FC<NoteListProps> = (props) => {
  const { project, user: sessionUser } = useAuth();
  const [getNotes, statusNotes, dataNotes] = Hooks.NoteODataWithParamsBetween();
  const { getUser } = useGetUserOnStore();
  const filterDisc = useDisclosure();
  const [selectedItem, setSelectedItem] = useState(null);
  const form = useForm();
  const [filterState, setFilterState] = useState({
    startDate: null as any,
    endDate: null as any,
    user: "0" as any,
  });

  function noteFilter(note: any) {
    if (filterState.user !== "0") {
      const noteUser = getUser(note.userID);
      const noteUserFullname = getUserFullname(noteUser);
      if (noteUserFullname !== filterState.user) {
        return false;
      }
    }

    if (filterState.startDate) {
      if (
        moment(note.date).diff(
          moment(filterState.startDate, "YYYY-MM-DD"),
          "seconds"
        ) < 0
      ) {
        return false;
      }
    }
    if (filterState.endDate) {
      if (
        moment(note.date).diff(
          moment(filterState.endDate, "YYYY-MM-DD"),
          "seconds"
        ) > 0
      ) {
        return false;
      }
    }

    return true;
  }

  function getUsers() {
    const users: any[] = [];
    (dataNotes?.data as any)?.forEach((note: any) => {
      const user = getUser(note.userID);
      const userFullname = getUserFullname(user);

      if (!users.includes(userFullname)) {
        users.push(userFullname);
      }
    });
    return users;
  }

  function userTypeFilter(x: any) {
    if (x.title === "Title") {
      return true;
    }

    let noteTypesIds: number[] = [];
    try {
      noteTypesIds = JSON.parse(x.title) as number[];
    } catch (error) {
      return true;
    }

    let hasSessionUserNoteType = sessionUser.userTypes?.some(
      (userType: any) => {
        if (!noteTypesIds) {
          return false;
        }
        return noteTypesIds?.includes(userType.id);
      }
    );

    return hasSessionUserNoteType;
  }

  const handleClickPdf = () => {
    localStorage.setItem("note-filter", JSON.stringify(filterState));
    window.open("/note-pdf", "_new");
  };

  const getUserFullname = (user?: IUser | undefined | null) => {
    if (!user) return "";

    return `${user.name} ${user.surname}`;
  };

  return (
    <Box>
      <HStack bgColor="white" p="5" justifyContent="space-between">
        <Heading size="md">Tüm Notlar</Heading>
        <HStack>
          <Button size="sm" colorScheme="purple" onClick={filterDisc.onToggle}>
            Filtre
          </Button>
          <Button size="sm" colorScheme="red" ml="2" onClick={handleClickPdf}>
            PDF
          </Button>
        </HStack>
      </HStack>
      <HStack justifyContent="end" pt="4" h="5" transition="100ms">
        {statusNotes !== "fulfilled" && (
          <HStack>
            <Spinner /> <Text>Yükleniyor</Text>
          </HStack>
        )}
      </HStack>
      <NoteCalendar
        onClick={(event) => {
          setSelectedItem(event.note);
        }}
        onRangeChange={(range) => {
          if (Array.isArray(range)) {
            getNotes(
              moment(range[0]).subtract(1, "days").format("YYYY-MM-DD"),
              moment(range[6]).add(1, "days").format("YYYY-MM-DD")
            );
          } else {
            getNotes(
              moment(range.start).subtract(1, "days").format("YYYY-MM-DD"),
              moment(range.end).add(1, "days").format("YYYY-MM-DD")
            );
          }
        }}
        events={
          dataNotes
            ? (dataNotes?.data as any)
                .filter(userTypeFilter)
                .filter(noteFilter)
                .map((item: any) => ({
                  note: item,
                  start: moment(item.date).toDate(),
                  end: moment(item.date).toDate(),
                  title: getUserFullname(getUser(item.userID)),
                }))
            : []
        }
      />

      <Modal isOpen={filterDisc.isOpen} onClose={filterDisc.onClose}>
        <ModalOverlay />
        <ModalContent zIndex="999999" position="relative">
          <ModalHeader>Filtre</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid columns={2} spacing="2">
              <FormControl>
                <FormLabel>Başlangıç Tarihi</FormLabel>
                <Input
                  value={filterState.startDate}
                  onChange={(e) =>
                    setFilterState((prev) => ({
                      ...prev,
                      startDate: e.target.value,
                    }))
                  }
                  type="date"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Bitiş Tarihi</FormLabel>
                <Input
                  type="date"
                  value={filterState.endDate}
                  onChange={(e) =>
                    setFilterState((prev) => ({
                      ...prev,
                      endDate: e.target.value,
                    }))
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Kullanıcı</FormLabel>
                <Select
                  onChange={(e) =>
                    setFilterState((prev) => ({
                      ...prev,
                      user: e.target.value,
                    }))
                  }
                >
                  <option value="0">Tümü</option>
                  {getUsers().map((user) => (
                    <option value={user}>{user}</option>
                  ))}
                </Select>
              </FormControl>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={filterDisc.onClose}>
              Tamam
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                setFilterState({
                  startDate: null as any,
                  endDate: null as any,
                  user: "0" as any,
                });
                filterDisc.onClose();
              }}
            >
              Temizle
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        size="5xl"
        scrollBehavior="inside"
        isOpen={!!selectedItem}
        onClose={() => setSelectedItem(null)}
      >
        <ModalOverlay />
        <ModalContent zIndex="999999" position="relative">
          <ModalBody>
            {selectedItem && (
              <DailyNoteItem forceShowImage item={selectedItem} index={0} />
            )}
            <Center mt="20">
              <Button
                colorScheme="red"
                size="sm"
                onClick={() => setSelectedItem(null)}
              >
                Kapat
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default NoteList;
