/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth } from "hooks/redux-hooks";
import { useLayoutEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Switch } from "react-router-dom";
import { Hooks } from "services";
import { authRoutes, routes } from "./routes";

export const AuthRouter = () => (
  <GoogleReCaptchaProvider reCaptchaKey="6Ldc_jopAAAAAMlrpFhn03o12ONl8NNY7Wadl-uU">
    <Switch>
      {authRoutes.map((routeItem, _index) => (
        <routeItem.route {...routeItem} key={_index} />
      ))}
    </Switch>
  </GoogleReCaptchaProvider>
);

export const RootRouter = () => {
  const [getUsers] = Hooks.UsersStore();
  const { project } = useAuth();

  useLayoutEffect(() => {
    getUsers(project.id);
  }, []);

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Ldc_jopAAAAAMlrpFhn03o12ONl8NNY7Wadl-uU">
      <Switch>
        {routes.map((routeItem, _index) => (
          <routeItem.route {...routeItem} key={_index} />
        ))}
      </Switch>
    </GoogleReCaptchaProvider>
  );
};

export default RootRouter;
