const tokenName = "otk";

export function setToken(token: string) {
  localStorage.setItem(tokenName, token);
}

export function setUserAndToken(token: string, user: any) {
  localStorage.setItem(tokenName, JSON.stringify({ token, user }));
}

export function setAuth(token: string, user: any, project: any) {
  localStorage.setItem(
    tokenName,
    JSON.stringify({ token, user, project, xToken: user.refreshToken })
  );
}

export function setTokenUpdate(token: string) {
  const data = getAuthInfo();

  localStorage.setItem(tokenName, JSON.stringify({ ...data, token }));
}

export function getToken() {
  return localStorage.getItem(tokenName);
}

export function getUserAndToken(): { token: string; user: any } | null {
  const item = localStorage.getItem(tokenName);
  if (item) {
    return JSON.parse(item);
  }
  return null;
}

export function getAuthInfo() {
  const item = localStorage.getItem(tokenName);
  if (item) {
    return JSON.parse(item);
  }
  return undefined;
}

export function clearToken() {
  localStorage.removeItem("refresh-token");
  return localStorage.removeItem(tokenName);
}

export function setRefreshToken(refreshToken: string) {
  return localStorage.setItem("refresh-token", refreshToken);
}

export function getRefreshToken() {
  return localStorage.getItem("refresh-token");
}
