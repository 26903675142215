import { store } from "store";
import * as storageManager from "./storage-manager";
import {
  clearAuth,
  setAuth,
  setAuthWithProject,
  setProject,
  tokenRefresh,
  tokenRefreshStart,
} from "store/auth/actions";
import { clearAllData } from "store/fetch/actions";
import { api, __mode__ } from "../consts";
import { Services } from "services";

const __dev__ = process.env.NODE_ENV === "development";

export const Authenticator = {
  Login: (token: string, user: any, decoded: any = null) => {
    storageManager.setUserAndToken(token, { ...user, apiType: __mode__ });
    storageManager.setRefreshToken(user.refreshToken);
    store.dispatch(
      setAuth({
        decoded,
        jwt: token,
        user: { ...user, apiType: __mode__ },
        refreshToken: user.refreshToken,
      })
    );
  },
  SetProject: (token: any, user: any, project: any) => {
    storageManager.setAuth(token, user, project);
    store.dispatch(setProject(project));
  },
  LoginWithProject: (
    token: any,
    user: any,
    project: any,
    decoded: any = null
  ) => {
    storageManager.setAuth(token, user, project);
    store.dispatch(
      setAuthWithProject({
        decoded,
        jwt: token,
        project,
        user,
        tokenRefreshing: false,
        refreshToken: user.refreshToken,
      })
    );
  },
  Logout: () => {
    storageManager.clearToken();
    store.dispatch(clearAuth());
    store.dispatch(clearAllData());
  },
  Compose: () => {
    const data = storageManager.getAuthInfo();
    if (!data) return Authenticator.Logout();

    if (!!!data.token || !!!data.user) return Authenticator.Logout();

    if (data.user.apiType != __mode__) return Authenticator.Logout();

    const refreshToken = storageManager.getRefreshToken();

    if (!!data.project) {
      return store.dispatch(
        setAuthWithProject({
          decoded: null,
          jwt: data.token,
          project: data.project,
          user: { ...data.user, refreshToken: refreshToken },
          refreshToken: refreshToken ?? "AUTHENTICATOR",
          tokenRefreshing: false,
        })
      );
    }

    return store.dispatch(
      setAuth({
        decoded: null,
        jwt: data.token,
        user: data.user,
        refreshToken: refreshToken,
      })
    );
  },
  GetAuthInfo: () => {
    return {
      isLoggedIn: store.getState().auth.isLoggedIn,
      user: store.getState().auth.user,
      project: store.getState().auth.project,
      token: store.getState().auth.jwt,
    };
  },
  RefreshToken: async () => {
    if (store.getState().auth.tokenRefreshing) return;

    store.dispatch(tokenRefreshStart());

    const refreshToken = storageManager.getRefreshToken();

    // if (!refreshToken) {
    //   Authenticator.Logout();
    // }

    try {
      const response = await Services.RefreshToken(refreshToken!);
      storageManager.setTokenUpdate(response.data.data.accessToken);
      storageManager.setRefreshToken(response.data.data.refreshToken);

      store.dispatch(
        tokenRefresh(
          response.data.data.accessToken,
          response.data.data.refreshToken
        )
      );
    } catch (error) {
      Authenticator.Logout();
    }
  },
};
